


















import {Component} from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SymptomTable from '@/components/tables/SymptomsTable.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {readSymptoms} from '@/store/crud/getters';
import {dispatchDeleteSymptom, dispatchGetSymptoms} from '@/store/crud/actions';

@Component({components: {ConfirmationModal, SymptomTable}})
export default class Symptoms extends AppComponent {
  get symptoms() {
    const symptoms = readSymptoms(this.$store);
    if (symptoms) {
      return symptoms;
    } else {
      return [];
    }
  }
  public fetchingSymptomsData = false;
  /**
   * created hook, it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches needed data from the store/ server and displays
   * loading spinner
   */
  public async fetchData() {
    this.fetchingSymptomsData = true;
    await this.$nextTick();
    await dispatchGetSymptoms(this.$store);
    this.fetchingSymptomsData = false;
  }

  /**
   * Delete a symptom and refresh the screen
   */
  public async deleteSymptom(symptomId: string) {
    await dispatchDeleteSymptom(this.$store, {symptomId});
    this.fetchData();
  }
}
